<template>
  <div class="row align-items-center justify-content-center text-center">
    <div class="col-12">
      <h1 class="font-32 mb-3">
        Welcome to showdigs
      </h1>
      <p class="font-17 mb-4">
        You've been invited to join <span class="font-weight-bold">
          {{ formState.isInvitedBy }}
        </span>'s showdigs account!
      </p>
      <ElForm
        ref="form"
        :model="payload"
        :rules="rules"
        @submit.native.prevent="submit"
      >
        <SdFloatLabel for-input="first_name">
          <ElFormItem
            prop="user[first_name]"
            class="mb-4"
          >
            <ElInput
              id="first_name"
              v-model="payload.user.first_name"
              placeholder="First name"
              label="test"
            />
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="last_name">
          <ElFormItem
            prop="user[last_name]"
            class="mb-4"
          >
            <ElInput
              id="last_name"
              v-model="payload.user.last_name"
              placeholder="Last name"
            />
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel
          for-input="phone_number"
        >
          <ElFormItem
            prop="user[phone_number]"
            class="mb-4"
          >
            <ElInput
              id="phone_number"
              v-model="payload.user.phone_number"
              class="input-with-append"
              type="tel"
              placeholder="Phone number"
            >
              <SdFloatLabel
                slot="append"
                for-input="phone_number_extension"
              >
                <ElFormItem prop="user[phone_number_extension]">
                  <ElInput
                    id="phone_number_extension"
                    v-model="payload.user.phone_number_extension"
                    class="input-phone-extension"
                    type="text"
                    placeholder="Ext"
                  />
                </ElFormItem>
              </SdFloatLabel>
            </ElInput>
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel
          with-optional
          for-input="public_phone_number"
          class="text-left"
        >
          <ElFormItem
            prop="user[public_phone_number]"
            class="mb-4"
          >
            <el-tooltip
              class="item"
              effect="light"
              content="Your phone number will be shown to prospects who wish to apply for your property"
              placement="right"
            >
              <ElInput
                id="public_phone_number"
                v-model="payload.user.public_phone_number"
                class="input-with-append"
                type="tel"
                placeholder="Public phone number"
              >
                <SdFloatLabel
                  slot="append"
                  for-input="public_phone_number_extension"
                >
                  <ElFormItem prop="user[public_phone_number_extension]">
                    <ElInput
                      id="public_phone_number_extension"
                      v-model="payload.user.public_phone_number_extension"
                      class="input-phone-extension"
                      type="text"
                      placeholder="Ext"
                    />
                  </ElFormItem>
                </SdFloatLabel>
              </ElInput>
            </el-tooltip>
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="password">
          <ElFormItem
            prop="user[password]"
            class="mb-4"
          >
            <el-tooltip
              class="item"
              effect="light"
              content="Must contain at least 6 characters"
              placement="right"
            >
              <ElInput
                id="password"
                v-model="payload.user.password"
                :type="
                  isPasswordVisible ? 'text' : 'password'
                "
                placeholder="Password"
              >
                <template slot="suffix">
                  <i
                    v-show="
                      payload.user.password.length
                    "
                    class="pointer px-1"
                    :class="
                      isPasswordVisible
                        ? 'sdicon-eye-slash'
                        : 'sdicon-eye'
                    "
                    @click="triggerPasswordVisibility"
                  />
                </template>
              </ElInput>
            </el-tooltip>
          </ElFormItem>
        </SdFloatLabel>
        <ElButton
          type="primary"
          class="w-100 mt-2"
          :loading="formState.loading"
          @click="submit"
        >
          Sign Up
        </ElButton>
        <div class="mt-2">
          <span
            class="text-gray-dark"
          >Have an account?
          </span>
          <router-link :to="{ name: 'auth.sign-in' }">
            Sign In
          </router-link>
        </div>
      </elform>
    </div>
    <div class="row font-15 justify-content-center">
      <div class="col-1">
        <i
          class="demo-icon sdicon-exclamation-circle"
        />
      </div>
      <div class="col-8">
        <span> Signing up for showdigs means you agree to the </span>
        <a
          href="https://www.showdigs.com/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>
        <span>and</span>
        <a
          href="https://www.showdigs.com/terms"
          target="_blank"
        >
          Terms of Service.
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/composition-api';

import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import Regex from '../../constants/Regex';
import { redirectToPropertiesPage, redirectToSignIn } from '../../router';

const regexConstants = Regex;
export default {
  setup(props, context) {
    const isPasswordVisible = ref(false);
    const formState = reactive(defaultFormState);
    const payload = reactive(defaultPayload);
    const rules = ref(formRules);
    if (context.root.$route.params.token) {
      prepareInvitedUser();
    }
    return {
      isPasswordVisible,
      formState,
      payload,
      submit,
      rules,
      triggerPasswordVisibility,
    };

    function triggerPasswordVisibility() {
      return (isPasswordVisible.value = !isPasswordVisible.value);
    }

    async function prepareInvitedUser() {
      try {
        const user = await context.root.$store.dispatch('Auth/getInvitedUser', context.root.$route.params.token);
        payload.user.email = user.email;
        payload.business.name = user.business.name || user.business.owner_name;
        formState.isInvitedBy = user.business.owner_name || user.business.name;
      } catch (error) {
        redirectToSignIn(context);
        showErrorNotify(context, error.message);
      }
    }

    async function submit() {
      formState.loading = true;
      try {
        payload.business.billing_email = payload.user.email;
        await context.refs.form.validate();
        const loggedUser = await context.root.$store.dispatch('Auth/registerBusinessMember', {
          token: context.root.$route.params.token,
          data: payload.user,
        });
        showSuccessNotify(context, `Welcome ${loggedUser.full_name}`);
        redirectToPropertiesPage(context);
      } catch (error) {
        formState.loading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }
  },
};
const formRules = {
  user: {
    first_name: [
      {
        required: true,
        type: 'string',
        message: 'First name is required',
      },
    ],
    last_name: [
      {
        required: true,
        type: 'string',
        message: 'Last name is required',
      },
    ],
    email: [
      {
        required: true,
        type: 'email',
        message: 'Email name is required',
      },
    ],
    password: [
      {
        required: true,
        message: 'Password is required',
      },
    ],
    phone_number: [
      {
        required: true,
        message: 'Phone number is required',
      },
      {
        pattern: regexConstants.phone_number,
        message: 'Enter a valid phone number',
      },
    ],
    phone_number_extension: [
      {
        pattern: regexConstants.numeric,
        max: 5,
        message: 'Enter a numeric extension',
      },
    ],
    public_phone_number: [
      {
        pattern: regexConstants.phone_number,
        message: 'Enter a valid phone number',
      },
    ],
    public_phone_number_extension: [
      {
        pattern: regexConstants.numeric,
        max: 5,
        message: 'Enter a numeric extension',
      },
    ],
  },
};

const defaultFormState = {
  isInvitedBy: null,
  loading: false,
};

const defaultPayload = {
  user: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '',
    phone_number_extension: '',
    public_phone_number: '',
    public_phone_number_extension: '',
  },
  business: {
    name: '',
    billing_email: '',
  },
};
</script>

<style lang="scss" scoped>
</style>
