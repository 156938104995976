<template>
  <RegisterByInviteForm
    v-if="isInvited"
  />
  <SignUpForm v-else />
</template>

<script>
import { computed } from '@vue/composition-api';

import SignUpForm from '../../components/auth/SignUpForm';
import RegisterByInviteForm from '../../components/auth/RegisterByInviteForm';

export default {
  components: { RegisterByInviteForm, SignUpForm },
  setup(props, context) {
    const isInvited = computed(() => Boolean(context.root.$route.params.token));
    return {
      isInvited,
    };
  },
};
</script>

<style lang="scss">
// todo check if still relevant
    .sdicon-exclamation-circle:before {
        line-height: 2.5rem;
        font-size: $--font-size-large;
    }

    .sdicon-exclamation-circle {
        color: gray-color(dark);
    }
</style>
