<template>
  <div class="row align-items-center justify-content-center text-center">
    <div
      v-if="uiFlags.currentStep === 'email'"
      class="col-12 pt-0"
    >
      <h1 class="mb-3 font-32">
        Sign up for Showdigs
      </h1>
      <p class="font-17 mb-5">
        Create your account to start boosting your leasing process
      </p>
      <ElForm
        ref="emailForm"
        class="mt-1"
        :model="payload"
        :rules="{email: formRules.email}"
        @submit.native.prevent="createUserWithEmail"
      >
        <SdFloatLabel for-input="email">
          <ElFormItem prop="email">
            <ElInput
              v-model="payload.email"
              type="email"
              placeholder="Work Email"
            />
          </ElFormItem>
        </SdFloatLabel>
        <ElButton
          type="primary"
          :loading="uiFlags.isLoading"
          class="w-100 mt-4"
          @click="createUserWithEmail"
        >
          Sign Up
        </ElButton>
      </ElForm>
      <div class="col-12 mt-3 font-15">
        Already have an account?
        <router-link :to="{name:'auth.sign-in'}">
          Log In
        </router-link>
      </div>
    </div>
    <div
      v-if="uiFlags.currentStep === 'details'"
      class="col-12"
    >
      <h1
        class="mb-5 font-32"
        :class="{'mt-5': $viewport.gt.md}"
      >
        Create your Showdigs account
      </h1>
      <ElForm
        ref="detailsForm"
        :model="payload"
        :rules="formRules"
        @submit.native.prevent="updateSignupDetails"
      >
        <SdFloatLabel for-input="email">
          <ElFormItem
            prop="email"
            class="mb-4"
          >
            <ElInput
              v-model="payload.email"
              type="email"
              placeholder="Email"
            />
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="first_name">
          <ElFormItem
            prop="first_name"
            class="mb-4"
          >
            <ElInput
              v-model="payload.first_name"
              placeholder="First name"
            />
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="last_name">
          <ElFormItem
            prop="last_name"
            class="mb-4"
          >
            <ElInput
              v-model="payload.last_name"
              placeholder="Last name"
            />
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="password">
          <ElFormItem
            prop="password"
            class="mb-4"
          >
            <el-tooltip
              class="item"
              effect="light"
              content="Must contain at least 6 characters"
              placement="right"
            >
              <ElInput
                v-model="payload.password"
                :type="uiFlags.isPasswordVisible ? 'text' : 'password'"
                placeholder="Password"
              >
                <template slot="suffix">
                  <i
                    v-show="payload.password.length"
                    class="pointer px-1"
                    :class="uiFlags.isPasswordVisible ? 'sdicon-eye-slash' : 'sdicon-eye'"
                    @click="triggerPasswordVisibility"
                  />
                </template>
              </ElInput>
            </el-tooltip>
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel for-input="phone_number">
          <ElFormItem
            prop="phone_number"
            class="mb-4"
          >
            <ElInput
              v-model="payload.phone_number"
              class="input-with-append"
              type="tel"
              placeholder="Phone number"
            >
              <SdFloatLabel
                slot="append"
                for-input="phone_number_extension"
              >
                <ElFormItem prop="phone_number_extension">
                  <ElInput
                    v-model="payload.phone_number_extension"
                    class="input-phone-extension"
                    type="text"
                    placeholder="Ext"
                  />
                </ElFormItem>
              </SdFloatLabel>
            </ElInput>
          </ElFormItem>
        </SdFloatLabel>
        <SdFloatLabel
          for-input="business_name"
          class="text-left"
        >
          <ElFormItem
            prop="business_name"
            class="mb-4"
          >
            <ElInput
              id="business_name"
              v-model="payload.business_name"
              placeholder="Business name"
            />
          </ElFormItem>
        </SdFloatLabel>
        <div class=" mt-3 mb-1 font-13 text-gray-dark text-left">
          By creating an account you agree to the Showdigs
          <a
            target="_blank"
            href="https://www.showdigs.com/terms"
          >Terms of Use</a>
        </div>
        <ElButton
          type="primary"
          :loading="uiFlags.isLoading"
          class="w-100 mt-2"
          @click="updateSignupDetails"
        >
          Next
        </ElButton>
      </ElForm>
    </div>
    <div
      v-if="uiFlags.currentStep === 'verification-sent'"
      class="col-12"
    >
      <h1 class="mb-3 font-32">
        Verify your email address
      </h1>
      <p class="font-17 mb-4">
        We've just sent you a verification email.
        Follow the instructions in the email to finish creating your
        Showdigs account.
      </p>
      <ElForm
        ref="verificationForm"
        class="mt-1"
        :model="payload"
        :rules="{email: formRules.email}"
        @submit.native.prevent="resendVerificationEmail"
      >
        <SdFloatLabel for-input="email">
          <ElFormItem prop="email">
            <ElInput
              v-model="payload.email"
              type="email"
              placeholder="Email"
            />
          </ElFormItem>
        </SdFloatLabel>
        <ElButton
          type="primary"
          :loading="uiFlags.isLoading"
          class="w-100 mt-4"
          @click="resendVerificationEmail"
        >
          Don't see it? Resend
        </ElButton>
      </ElForm>
    </div>
    <div
      v-if="uiFlags.currentStep !== 'verification-sent'"
      class="col-12 justify-content-center mb-4"
    >
      <span class="text-gray-dark font-15">Want to earn money showing properties? </span>
      <a href="https://www.showdigs.com/agents">
        Apply here
      </a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import Regex from '../../constants/Regex';
import SdFloatLabel from '../common/form/SdFloatLabel';

const regexConstants = Regex;
export default {
  components: { SdFloatLabel },
  setup(props, context) {
    const store = context.root.$store;
    const accountOwnerRef = ref(null);
    const payload = reactive(defaultPayload);
    const uiFlags = reactive({
      isLoading: false,
      isPasswordVisible: false,
      currentStep: !store.getters['Auth/isLoggedIn'] ? 'email' : 'verification-sent', // email, details, verification-sent
    });

    if (store.getters['Auth/isLoggedIn']) {
      payload.email = store.getters['Auth/user'].email;
    }

    payload.plan_name = context.root.$route.query?.plan_name;

    return {
      accountOwnerRef,
      payload,
      uiFlags,
      formRules,
      createUserWithEmail,
      updateSignupDetails,
      resendVerificationEmail,
      triggerPasswordVisibility,
    };

    async function createUserWithEmail() {
      uiFlags.isLoading = true;
      try {
        payload.email = payload.email.trim();
        await context.refs.emailForm.validate();
        accountOwnerRef.value = await store.dispatch('Auth/createUserWithEmail', payload);
        uiFlags.currentStep = 'details';
        store.commit('Ui/setTestimonial', 1);
      } catch (error) {
        if (error.message) {
          showErrorNotify(context, error.message, { duration: 0 });
        }
      }
      uiFlags.isLoading = false;
    }

    async function updateSignupDetails() {
      uiFlags.isLoading = true;
      try {
        await context.refs.detailsForm.validate();
        accountOwnerRef.value = await store.dispatch('Auth/updateSignupDetails', payload);
        uiFlags.currentStep = 'verification-sent';
        store.commit('Ui/setTestimonial', 2);
        if (process.env.VUE_APP_ENV !== 'production') {
          const url = accountOwnerRef.value.verify_url;
          /* eslint-disable no-undef */
          const notificationOptions = { duration: 0, dangerouslyUseHTMLString: true };
          showSuccessNotify(context, `<a class="d-block" target="_blank" href="${url}">${url}</a>`, notificationOptions);
        }
      } catch (error) {
        if (error.message) {
          showErrorNotify(context, error.message);
        }
      }
      uiFlags.isLoading = false;
    }

    async function resendVerificationEmail() {
      uiFlags.isLoading = true;
      try {
        await context.refs.verificationForm.validate();
        await store.dispatch('Auth/resendVerificationEmail', { email: payload.email, plan_name: payload.plan_name });
        showSuccessNotify(context, `We re-sent a verification email to ${payload.email}. Please check your inbox again.`);
      } catch (error) {
        showErrorNotify(context, error.message);
      }
      uiFlags.isLoading = false;
    }

    function triggerPasswordVisibility() {
      return (uiFlags.isPasswordVisible = !uiFlags.isPasswordVisible);
    }
  },
};
const defaultPayload = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  phone_number: '',
  phone_number_extension: '',
  business_name: '',
  plan_name: null,
};
const formRules = {
    email: [
      {
        required: true,
        type: 'email',
        message: 'Email is required',
      },
    ],
    first_name: [
      {
        required: true,
        type: 'string',
        message: 'First name is required',
      },
    ],
    last_name: [
      {
        required: true,
        type: 'string',
        message: 'Last name is required',
      },
    ],
    password: [
      {
        required: true,
        message: 'Password is required',
      },
    ],
    phone_number: [
      {
        required: true,
        pattern: regexConstants.phone_number,
        message: 'Enter a valid phone number',
      },
    ],
    phone_number_extension: [
      {
        pattern: regexConstants.numeric,
        max: 5,
        message: 'Enter a numeric extension',
      },
    ],
    business_name: [
      {
        required: true,
        type: 'string',
        message: 'Business name is required',
      },
    ],
};

</script>
